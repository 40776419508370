@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

// This base file determines the current theme. Go to that file to change themes
// @import 'themes/base';

// Theme(s)
// @import 'node_modules/@engineering11/ui-theme-blue/stylesheets/theme';
@import 'node_modules/@engineering11/ui-theme-munsell/stylesheets/theme';

// E11 Component Styles
@import 'node_modules/@engineering11/ui-lib/assets/stylesheets/components/base.scss';

// Material icon sizes
@import './libs/material-icon-sizes';

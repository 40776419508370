// Google Font Icon Helpers
/* Rules for sizing the icon. */
// This xs is an exception to the standard
.material-icons.md-14 {
  // xs
  font-size: 14px;
}

.material-icons.md-18 {
  // sm
  font-size: 18px;
}

.material-icons.md-24 {
  // md
  font-size: 24px;
}

.material-icons.md-36 {
  // lg
  font-size: 36px;
}

.material-icons.md-48 {
  // xl
  font-size: 48px;
}

.material-icons-outlined.md-14 {
  font-size: 14px;
}

.material-icons-outlined.md-18 {
  font-size: 18px;
}

.material-icons-outlined.md-24 {
  font-size: 24px;
}

.material-icons-outlined.md-36 {
  font-size: 36px;
}

.material-icons-outlined.md-48 {
  font-size: 48px;
}

.material-icons-sharp.md-14 {
  font-size: 14px;
}

.material-icons-sharp.md-18 {
  font-size: 18px;
}

.material-icons-sharp.md-24 {
  font-size: 24px;
}

.material-icons-sharp.md-36 {
  font-size: 36px;
}

.material-icons-sharp.md-48 {
  font-size: 48px;
}

.material-icons-two-tone.md-14 {
  font-size: 14px;
}

.material-icons-two-tone.md-18 {
  font-size: 18px;
}

.material-icons-two-tone.md-24 {
  font-size: 24px;
}

.material-icons-two-tone.md-36 {
  font-size: 36px;
}

.material-icons-two-tone.md-48 {
  font-size: 48px;
}

// Global Application Layout
.layout-app {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    min-height: 100vh;

    header {
        position: fixed;
        display: flex;
        align-items: center;
        z-index: 10;
        height: 4rem; // Should only be needed here. Not worth config
        width: 100%;
        .app-header-logo {
            display: flex;
            padding-left: 1rem;
        }
        .app-header-content {
            display: flex;
            flex-grow: 1;
            padding: 0 1rem 0 2rem;
        }
    }

    footer {
        display: flex;
        width: 100%;
        padding: 2rem 1rem;
    }

    .layout-main {
        position: relative;
        display: flex;
        flex-grow: 1;
        margin-top: 6.5rem; // Header height plus content padding (5 + 1.5)
    
        .layout-nav-left {
            flex-grow: 1;
            nav {
                height: 100%;
            }
        }
    
        .layout-content {
            width: 100%;
            padding: 0 1.5rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .layout-app {
        .layout-main {
            .layout-nav-left {
                position: absolute;
                height: 100%;
            }
        }
    }
}
/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";

// Global Layout
@use './styles-layout.scss' as *;
@use './styles-layout-admin.scss' as *;

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;


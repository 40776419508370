// Global Application Layout
.layout-app-admin {
    position: relative;
    display: flex;
    flex-grow: 1;

    .left {
        height: 100vh;
        transition: width 0.2s ease-in-out 0s;
        &.closed {
            width: calc(2rem + 24px);
            transition: width 0.2s ease-in-out 0s;
        }

        app-nav-left {
            height: 100vh;
        }
    }
    .right {
        display: flex;
        flex-grow: 1;
    }


    .layout-nav-left {
        height: 100vh;
        flex-grow: 1;
        nav {
            height: 100%;
        }
    }

    .layout-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        .layout-router-outlet {
            position: relative;
            display: flex;
            flex-grow: 1;
            padding: 1rem;
        }
        header {
            display: flex;
            align-items: center;
            z-index: 10;
            height: 4rem; // Should only be needed here. Not worth config
            width: 100%;
            .app-header-logo {
                display: flex;
                padding-left: 1rem;
            }
            .app-header-content {
                display: flex;
                flex-grow: 1;
                padding: 0 1rem 0 2rem;
            }
        }
    
        footer {
            display: flex;
            width: 100%;
            padding: 2rem 1rem;
        }
    }

}


@media screen and (max-width: 767px) {
    .layout-app {
        .layout-main {
            .layout-nav-left {
                position: absolute;
                height: 100%;
            }
            .layout-content {
                margin-left: 3.5rem;
            }
        }
    }
}